'use strict';

angular.module('kljDigitalLibraryApp')
  .factory('PaginationService', function () {
    var totalCount = 200;
    var factory = {};

    factory.setCount = function(count){
      totalCount = count;
    }

    factory.getCount = function(){
      return totalCount;
    }
    return factory;
  });
